<template>
  <FormFlow
    v-if="!emailAccepted"
    :image-section-alt="__('auth', 'account-registration.image-section.alt')"
    :image-section-src="__('auth', 'account-registration.image-section.url')"
  >
    <template #form>
      <h2 class="form-flow-heading">
        {{ __('auth', 'account-registration.main-heading.text') }}
      </h2>
      <form
        v-if="signUpInputs"
        action=""
        class="account-registration-form"
        @submit.prevent="signUpUser"
      >
        <div v-for="item in signUpInputs" :key="item.name">
          <BaseInput
            v-model="item.value"
            :error-message="getFieldError(registrationErrors, item.name)"
            :id-name="item.name"
            :label="item.label"
            :type="item.type"
            width="320px"
          />
        </div>
        <div
          :class="{
            error:
              registrationErrors &&
              findErrorField(registrationErrors, 'receive_emails'),
          }"
        >
          <div class="account-registration-checkbox">
            <div class="checkbox-left-container">
              <SvgIcon class="mail-icon" name="mail" />
              <span>
                {{ __('forms', 'agree-to-receive-emails.checkbox') }}
              </span>
            </div>
            <label class="switch" for="emails">
              <input id="emails" v-model="receiveEmails" type="checkbox" />
              <div class="round slider"></div>
            </label>
          </div>
          <div
            v-if="
              registrationErrors &&
              findErrorField(registrationErrors, 'receive_emails')
            "
            class="errors"
          >
            <div v-for="i in registrationErrors" :key="i.field">
              <p v-if="i.field === 'receive_emails'">{{ i.message }}</p>
            </div>
          </div>
        </div>
        <BaseButton
          class="submit-button"
          :class="{ loading }"
          :text="__('forms', 'sign-up-button.text')"
          type="submit"
        />
      </form>
    </template>
    <template #alternative-options>
      <div class="alternative-option">
        <span>{{
          __('auth', 'account-registration.agree-to-receive-emails.text')
        }}</span>
        <a :href="pageLinks ? pageLinks.value.terms_url : ''" target="_blank">{{
          __('auth', 'account-registration.terms-conditions-link.text')
        }}</a>
        <span> {{ __('global', 'and') }} </span>
        <a
          :href="pageLinks ? pageLinks.value.privacy_policy_url : ''"
          target="_blank"
          >{{ __('auth', 'account-registration.privacy-policy-link.text') }}</a
        >
      </div>
    </template>
  </FormFlow>
  <CheckEmail
    v-else
    :receive-emails="receiveEmails"
    :registration-email="registrationEmail"
    @change-email="handleEmailVal"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { getSignUpInputs, Input } from '@/Helpers/Forms'
import { findErrorField } from '@/Helpers/Global'
import { __ } from '@/Helpers/i18n'
import { analyticsSignUp } from '@/Helpers/Analytics'
import { postRegistration } from '@/Api/Registration'
import BaseInput from '@/Components/BaseInput.vue'
import BaseButton from '@/Components/BaseButton.vue'
import FormFlow from '@/Components/FormFlow.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'
import CheckEmail from './CheckEmail.vue'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { PageLinkSetting } from '@/Types/Setting'
import { getFieldError } from '@/Helpers/Errors'

const globalStore = useGlobalStore()

const signUpInputs = ref<Input[]>(getSignUpInputs())
const registrationErrors = ref()
const loading = ref(false)

const emailAccepted = ref(false)
const registrationEmail = ref('')
const receiveEmails = ref(false)

const handleEmailVal = () => {
  emailAccepted.value = !emailAccepted.value
  registrationEmail.value = ''
}

const pageLinks = computed(
  () =>
    globalStore.getSettingByKey('pagelinksetting') as
      | PageLinkSetting
      | undefined,
)

const signUpUser = async () => {
  loading.value = true
  registrationErrors.value = null
  const data: { [key: string]: any } = {}
  signUpInputs.value.forEach((item) => {
    data[item.name] = item.value
    if (item.type === 'email') {
      registrationEmail.value = item.value
    }
  })
  data.terms_accepted = true
  data.receive_emails = receiveEmails.value
  await postRegistration(data).catch((error: any) => {
    registrationErrors.value = error.response.data
  })

  loading.value = false
  if (registrationErrors.value) return
  emailAccepted.value = true
  analyticsSignUp(data)
}
</script>

<style lang="scss" scoped>
.form-flow-secondary-heading {
  max-width: 445px;
  text-align: center;
}

.account-registration-form {
  @include centered-flex-column;
  margin-top: 32px;

  @media (min-width: 768px) {
    margin-top: 40px;
  }

  .submit-button {
    margin-top: 48px;
  }
}

.account-registration-checkbox {
  @include centered-flex;
  justify-content: space-between;
  background: $gray-6;
  width: 320px;
  height: 56px;
  padding: 16px;
  border-radius: 16px;
  gap: 16px;

  .checkbox-left-container {
    display: flex;
    align-items: center;
    gap: 12px;

    .mail-icon {
      height: 32px;
      width: 32px;
    }

    span {
      font-size: 12px;
      max-width: 200px;
    }
  }
}

.switch {
  display: inline-block;
  height: 24px;
  position: relative;
  width: 42px;
}

.switch input {
  display: none;
}

.slider {
  background-color: $gray-8;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: '';
  height: 16px;
  left: 4px;
  position: absolute;
  transition: 0.3s;
  width: 16px;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $checkbox-green;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

.slider:after {
  content: '';
  position: absolute;
  top: 7.4px;
  left: 10px;
  width: 2.5px;
  height: 5.3px;
  border: solid $white;
  border-width: 0 1.8px 1.8px 0;
  transform: translate(0, 0) rotate(45deg); /* Initially hidden */
  transition: transform 0.3s ease;
}

input:checked + .slider:after {
  transform: translate(16px, 0) rotate(45deg); /* Show the checkmark */
  border: solid $checkbox-green;
  border-width: 0 1.8px 1.8px 0;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
