<template>
  <div class="form-flow-wrapper">
    <div class="input-section">
      <div class="logo">
        <OptimizedImg
          alt="Logo"
          :size="{ height: 18, width: 148 }"
          src="selfnamed-logo.svg"
        />
      </div>
      <div class="progress-container">
        <router-link
          v-if="showPreviousStepButton"
          class="back-button"
          :to="currentProgress > 1 ? route.path : `/${langStore.getLang()}`"
          @click="goPreviousStep"
        >
          <SvgIcon
            :alt="__('global', 'back')"
            class="arrow-left"
            name="arrow-left"
          />
          {{ __('global', 'back') }}
        </router-link>
        <ProgressBar
          v-if="progressBar"
          :current-progress="currentProgress"
          :progress-bar="progressBar"
        />
      </div>
      <div class="form-container">
        <slot name="form"></slot>
      </div>
      <div class="alternative-options">
        <slot name="alternative-options"></slot>
      </div>
    </div>
    <div class="image-section">
      <img :alt="imageSectionAlt" :src="imageSectionSrc" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProgressBarItem } from '@/Api/PublicUserOnboarding'
import ProgressBar from '@/Components/ProgressBar.vue'
import { __ } from '@/Helpers/i18n'
import SvgIcon from '@/Partials/SvgIcon.vue'
import OptimizedImg from '@/Partials/ui/OptimizedImg.vue'
import useLangStore from '@/Store/useLangStore'
import { useRoute, useRouter } from 'vue-router'

withDefaults(
  defineProps<{
    currentProgress?: number
    progressBar?: ProgressBarItem[]
    showPreviousStepButton?: boolean
    showLogo?: boolean
    imageSectionSrc: string
    imageSectionAlt: string
  }>(),
  {
    showLogo: true,
    showPreviousStepButton: true,
    currentProgress: 0,
  },
)

const langStore = useLangStore()
const route = useRoute()
const router = useRouter()

const goPreviousStep = () => {
  router.back()
}
</script>

<style lang="scss">
.form-flow-heading {
  text-transform: none;
  font-weight: 500;
  letter-spacing: 0;
  font-size: 24px;
  text-align: center;
  letter-spacing: -0.03em;
  @media (min-width: 768px) {
    text-wrap: nowrap;
  }
}

.form-flow-secondary-heading {
  text-transform: none;
  font-weight: 500;
  letter-spacing: -0.02rem;
  line-height: 24px;
  color: $gray-7;
  font-size: 16px;
  margin-top: 20px;
  text-align: center;
}

.form-flow-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: 1025px) {
    display: grid;
    grid-template-columns: 59.35% 40.65%;
    height: 100vh;
    overflow: hidden;
  }

  .input-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px 20px 20px;
    height: 100%;
    @media (min-width: 768px) {
      flex: 1;
      padding: 64px 48px 20px 48px;
    }

    @media (min-width: 1025px) {
      overflow-y: scroll;
      scrollbar-width: none;
    }

    .progress-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      width: 100%;
      height: 16px;
      margin-top: 5rem;
      @media (min-width: 768px) {
        margin-top: 5rem;
      }
    }

    .back-button {
      display: flex;
      align-items: center;
      left: 0;
      gap: 8px;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      color: $black;
      .arrow-left {
        height: 16px;
        width: 16px;
      }
    }

    .form-container {
      @include centered-flex;
      margin-top: 5rem;
      flex-direction: column;
      width: 100%;
      @media (min-width: 768px) {
        margin-top: 60px;
      }

      .continue-button {
        margin-top: 20px;
      }
    }
  }

  .input-section::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
  }

  .alternative-options {
    margin-top: 15px;
    max-width: 272px;
    text-align: center;
    .alternative-option {
      margin-top: 8px;
      font-size: 1.2rem;
      span {
        color: $gray-3;
      }
      a {
        color: $black;
        text-decoration: none;
        margin: 0 2.5px;
      }
    }
  }

  .image-section {
    display: none;
    @media (min-width: 1025px) {
      display: flex;
      justify-content: end;
      height: 100vh;
      width: 100%;
      padding: 10px 10px 10px 0;
      img {
        border-radius: 100px;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
</style>
