import { computed, ref, watch } from 'vue'
import { defineStore } from 'pinia'
import { useRoute } from 'vue-router'
import useScreenBreakpoints from '@/Composables/UseScreenBreakpoints'

export const GLOBAL_MESSAGE_BANNER_DEFAULT_HEIGHT_PX = 40
const GLOBAL_NAVIGATION_HEIGHT_PX = 60

const checkIfIncludesRoute = (
  array: string[],
  route: ReturnType<typeof useRoute>['name'],
) => typeof route === 'string' && array.some((item) => route.includes(item))

//TODO: refactor - maybe use router meta
const routesWithoutGlobalHeaderAndFooter = [
  'studio',
  'profile',
  'profile-dashboard',
  'view-order',
  'dropshipping-stores',
  'dropshipping-connect-store',
  'dropshipping-view-store',
  'ds-product-details',
  'ds-product-choose',
  'ds-product-view',
  'ds-choose-mockups',
  'my-products',
  'payment-method-form',
  'collection-box-customization',
  'collection-box-overview',
  'collection-box-product-design',
]

const routesWithoutGlobalHeader = [
  ...routesWithoutGlobalHeaderAndFooter,
  'product-open',
]

const routesWithoutGlobalFooter = [
  ...routesWithoutGlobalHeaderAndFooter,
  'sample-sets-widget',
  'store-connections',
]

const routesWithoutGlobalBanner = ['studio']

export default defineStore('layout', () => {
  const route = useRoute()
  const { isMobile } = useScreenBreakpoints()

  const mobileMenuOpen = ref(false)
  const mobileMenuActiveItem = ref<string | null>(null)
  watch(mobileMenuOpen, () => (mobileMenuActiveItem.value = null))

  const expandedGlobalMessageBannerHeight = ref(
    GLOBAL_MESSAGE_BANNER_DEFAULT_HEIGHT_PX,
  )
  const currentGlobalMessageBannerHeight = ref(
    GLOBAL_MESSAGE_BANNER_DEFAULT_HEIGHT_PX,
  )
  watch(currentGlobalMessageBannerHeight, (n) => {
    if (
      expandedGlobalMessageBannerHeight.value ===
      GLOBAL_MESSAGE_BANNER_DEFAULT_HEIGHT_PX
    ) {
      expandedGlobalMessageBannerHeight.value = n
    }
  })

  const mobileMenuVisible = computed(
    () => isMobile.value && mobileMenuOpen.value,
  )

  const setGlobalMessageBannerHeight = (value: number) => {
    if (mobileMenuVisible.value || value > 0) {
      currentGlobalMessageBannerHeight.value = value
    }
  }

  const showGlobalBanner = computed(
    () =>
      !checkIfIncludesRoute(routesWithoutGlobalBanner, route.name) &&
      !mobileMenuVisible.value,
  )

  const showHeaderContent = computed(
    () => !checkIfIncludesRoute(routesWithoutGlobalHeader, route.name),
  )

  const showFooter = computed(
    () => !checkIfIncludesRoute(routesWithoutGlobalFooter, route.name),
  )

  const expandedHeaderHeight = computed(
    () =>
      GLOBAL_NAVIGATION_HEIGHT_PX +
      GLOBAL_MESSAGE_BANNER_DEFAULT_HEIGHT_PX +
      'px',
  )

  const currentHeaderHeight = computed(
    () =>
      GLOBAL_NAVIGATION_HEIGHT_PX +
      currentGlobalMessageBannerHeight.value +
      'px',
  )

  return {
    mobileMenuOpen,
    mobileMenuActiveItem,
    setGlobalMessageBannerHeight,
    showGlobalBanner,
    showHeaderContent,
    showFooter,
    expandedHeaderHeight,
    currentHeaderHeight,
  }
})
