import { Error } from '@/Types/Error'

export type ErrorData = {
  response?: {
    data?: Error[]
    status: number
  }
}
export const setErrors = (payload: ErrorData) => {
  return payload?.response?.data ?? null
}

export const getFieldError = (errors: any, fieldName: string) => {
  const inputFieldError = errors
    ? errors.find((error: any) => error.field === fieldName)
    : null
  return inputFieldError ? inputFieldError.message : ''
}
