import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import privateTestingRoutes from './private-testing-routes'
import useLangStore from './Store/useLangStore'
import { getProductBySlug } from '@/Api/Products'
import { getDesign, getDesignConstructorData } from '@/Api/Design'
import { getStoreProduct } from '@/Api/StoreProduct'
import AccountRegistration from './Pages/AccountRegistration.vue'
import { PAGE_LAYOUTS } from './Helpers/Constants'
import { FEATURE_FLAGS } from './Helpers/Global'
import ProvideEmail from './Pages/ProvideEmail.vue'
import { useLoginStore } from './Store/useLoginStore'

const Home = () => import('./Pages/Home.vue')
const Cart = () => import('./Pages/Cart.vue')
const Checkout = () => import('./Pages/Checkout.vue')
const DesignerToolPage = () => import('./Pages/DesignerToolPage.vue')
const LayoutDesignerToolPage = () =>
  import('./Pages/LayoutDesignerToolPage.vue')
const PaymentResponse = () => import('./Pages/PaymentResponse.vue')
const RegistrationConfirm = () => import('./Pages/RegistrationConfirm.vue')
const ProfilePage = () => import('./Pages/ProfilePage.vue')
const ProfileDashboardPage = () =>
  import('./Pages/ProfileDashboard/ProfileDashboardPage.vue')
const OrdersPage = () => import('./Pages/Orders/OrdersPage.vue')
const ProfileFiles = () => import('./Pages/ProfileFiles.vue')
const ResetPassword = () => import('./Pages/ResetPassword.vue')
const ProfileDetails = () => import('./Pages/ProfileDetails.vue')
const ViewOrderPage = () => import('./Pages/ViewOrderPage.vue')
const Error = () => import('./Pages/Error.vue')
const SampleSetsPage = () => import('./Pages/SampleSetsPage.vue')
const DropshippingStoresPage = () =>
  import('./Pages/DropshippingStores/DropshippingStoresPage.vue')
const DropshippingConnectStorePage = () =>
  import('./Pages/DropshippingConnectStorePage.vue')
const DropshippingViewStorePage = () =>
  import('./Pages/DropshippingViewStore/DropshippingViewStorePage.vue')
const DropshippingProductLayout = () =>
  import(
    './Pages/DropshippingProductCustomization/DropshippingProductLayout.vue'
  )
const DropshippingProductDetailsPage = () =>
  import(
    './Pages/DropshippingProductCustomization/DropshippingProductDetailsPage.vue'
  )
const DropshippingChooseMockupsPage = () =>
  import(
    './Pages/DropshippingProductCustomization/DropshippingChooseMockupsPage.vue'
  )
const DropshippingChooseProductPage = () =>
  import(
    './Pages/DropshippingProductCustomization/DropshippingChooseProductPage.vue'
  )
const DropshippingViewProductPage = () =>
  import(
    './Pages/DropshippingProductCustomization/DropshippingViewProductPage.vue'
  )
const MyProductsPage = () => import('./Pages/MyProducts/MyProductsPage.vue')
const PaymentMethodFormPage = () => import('./Pages/PaymentMethodFormPage.vue')
const OnBoardingPage = () => import('./Pages/OnBoarding/OnBoardingPage.vue')
const CreateAccountPage = () => import('./Pages/CreateAccountPage.vue')
const LoginPage = () => import('./Pages/Login/LoginPage.vue')
const PasswordConfirm = () => import('./Pages/PasswordConfirm.vue')
const PasswordResetConfirm = () => import('./Pages/PasswordResetConfirm.vue')
const PasswordReset = () => import('./Pages/PasswordReset.vue')

// Custom Collection Box
const CollectionBoxCustomizationPage = () =>
  import('./Pages/CustomizeCollectionBox/CollectionBoxCustomizationPage.vue')
const CollectionBoxProductOverviewPage = () =>
  import('./Pages/CustomizeCollectionBox/CollectionBoxProductOverviewPage.vue')
const CollectionBoxProductDesignPage = () =>
  import('./Pages/CustomizeCollectionBox/CollectionBoxProductDesignPage.vue')
const DropshippingLandingPage = () =>
  import('./Pages/DropshippingLanding/DropshippingLandingPage.vue')

const idPropFunc: RouteRecordRaw['props'] = (route) => ({
  id: Number(route.params.id),
})

const StoreConnectionsPage = () =>
  import('./Pages/Auth/StoreConnectionsPage.vue')

const routes: RouteRecordRaw[] = [
  {
    path: '/:lang',
    component: Home,
    name: 'home',
  },
  {
    path: '/:lang/studio/:slug/:designId?',
    component: DesignerToolPage,
    name: 'studio',
    strict: true,
    props(route: RouteLocationNormalized) {
      return {
        designId: route.params.designId
          ? Number(route.params.designId)
          : undefined,
        preserveStore: route.query.preserveStore === 'true',
        showDropshippingFooter: route.query.showDropshippingFooter === 'true',
        turnOnPreviewMode: route.query.turnOnPreviewMode === 'true',
        storeProductId: route.query.storeProductId
          ? Number(route.query.storeProductId)
          : undefined,
        collectionBoxSlug: route.query.collectionBox || null,
        isBox: route.query.isBox === 'true',
        boxDesignId: route.query.boxDesignId
          ? Number(route.query.boxDesignId)
          : null,
        design: route.meta.design,
        designConstructorData: route.meta.designConstructorData || null,
      }
    },
    async beforeEnter(to, from, next) {
      if (!to.params.designId) {
        next()
        return
      }

      const [design, designConstructorData] = await Promise.all([
        getDesign(Number(to.params.designId)),
        getDesignConstructorData(Number(to.params.designId)),
      ])

      if (!design || !designConstructorData) {
        next(withLang({ name: 'error' }))
        return
      }

      to.meta.design = design
      to.meta.designConstructorData = designConstructorData

      next()
      return
    },
  },
  {
    path: '/:lang/layout-studio/:predefinedDesignId/:layoutCode',
    component: LayoutDesignerToolPage,
    name: 'layout-studio',
    strict: true,
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/collection-box/:productSlug/:boxDesignId?',
    component: CollectionBoxCustomizationPage,
    name: 'collection-box-customization',
    strict: true,
    meta: { requiresAuth: true },
    redirect: { name: 'collection-box-overview' },
    props(route: RouteLocationNormalized) {
      return {
        productSlug: route.params.productSlug,
        collectionBoxProduct: route.meta.collectionBoxProduct,
        storeProductData: route.meta.storeProductData,
      }
    },
    async beforeEnter(to, from, next) {
      if (!to.params.productSlug) {
        next(withLang({ name: 'error' }))
        return
      }

      await Promise.all([
        getProductBySlug(to.params.productSlug.toString(), [
          'gallery_photos',
          'collection_box_products',
          'collection_box_products.gallery_photos',
          'related_products',
        ]),
        to.params.boxDesignId
          ? getDesign(Number(to.params.boxDesignId), ['storeProducts'])
          : null,
        to.query.storeProductId
          ? getStoreProduct(Number(to.query.storeProductId)).then(
              (res) => res?.data,
            )
          : null,
      ])
        .then(([collectionBoxProduct, collectionBoxDesign, storeProduct]) => {
          if (
            !collectionBoxProduct ||
            (to.params.boxDesignId &&
              (!collectionBoxDesign ||
                !collectionBoxDesign.is_active ||
                collectionBoxDesign.product_id !== collectionBoxProduct.id)) ||
            (to.query.storeProductId && !storeProduct)
          ) {
            next(withLang({ name: 'error' }))
            return
          }

          if (
            !to.query.storeProductId &&
            collectionBoxDesign?.is_store_product
          ) {
            next(withLang({ name: 'dropshipping-stores' }))
          }

          to.meta.storeProductData = storeProduct
          to.meta.collectionBoxProduct = collectionBoxProduct
          next()
        })
        .catch((error: any) => {
          console.error(error)
          next(withLang({ name: 'error' }))
        })
    },
    children: [
      {
        path: '',
        component: CollectionBoxProductOverviewPage,
        name: 'collection-box-overview',
        strict: true,
        meta: { requiresAuth: true },
        props(route: RouteLocationNormalized) {
          return {
            boxDesignId: Number(route.params.boxDesignId),
          }
        },
      },
      {
        path: 'product-design/:productId',
        component: CollectionBoxProductDesignPage,
        name: 'collection-box-product-design',
        strict: true,
        props(route: RouteLocationNormalized) {
          return {
            boxDesignId: route.params.boxDesignId
              ? Number(route.params.boxDesignId)
              : null,
            productId: Number(route.params.productId),
          }
        },
        async beforeEnter(to, from, next) {
          if (!to.params.productId || isNaN(Number(to.params.productId))) {
            next(withLang({ name: 'error' }))
            return
          }

          next()
        },
      },
    ],
  },
  {
    path: '/:lang/sample-sets-widget',
    component: SampleSetsPage,
    name: 'sample-sets-widget',
    strict: true,
  },
  {
    path: '/:lang/skincare-drop-shipping',
    component: DropshippingLandingPage,
    name: 'dropshipping',
    strict: true,
  },
  {
    path: '/:lang/cart',
    component: Cart,
    name: 'cart',
    strict: true,
  },
  {
    path: '/:lang/checkout',
    component: Checkout,
    name: 'checkout',
    strict: true,
  },
  {
    path: '/:lang/payment-response/:reference',
    component: PaymentResponse,
    name: 'payment-response',
    strict: true,
  },
  {
    path: '/:lang/social-confirm/:id/:token',
    component: RegistrationConfirm,
    name: 'registration-confirm-social',
    strict: true,
    meta: {
      layout: FEATURE_FLAGS.SHOW_SIGN_UP_FLOW ? PAGE_LAYOUTS.FORM_FLOW : '',
    },
  },
  {
    path: '/:lang/email-change-confirm/:id/:token',
    component: RegistrationConfirm,
    name: 'registration-confirm-email-change',
    strict: true,
    meta: {
      layout: FEATURE_FLAGS.SHOW_SIGN_UP_FLOW ? PAGE_LAYOUTS.FORM_FLOW : '',
    },
  },
  {
    path: '/:lang/confirmation-retries/:id/:token',
    component: RegistrationConfirm,
    name: 'registration-confirm-email-retry',
    strict: true,
    meta: {
      layout: FEATURE_FLAGS.SHOW_SIGN_UP_FLOW ? PAGE_LAYOUTS.FORM_FLOW : '',
    },
  },
  {
    path: '/:lang/profile',
    component: ProfilePage,
    name: 'profile',
    meta: { requiresAuth: true },
    strict: true,
    redirect: {
      name: 'profile-dashboard',
    },
    children: [
      {
        path: 'dashboard',
        component: ProfileDashboardPage,
        name: 'profile-dashboard',
        strict: true,
        meta: { child: true, requiresAuth: true },
      },
      {
        path: 'orders',
        component: OrdersPage,
        name: 'profile-orders',
        strict: true,
        meta: { child: true, requiresAuth: true },
      },
      {
        path: 'orders/:reference',
        component: ViewOrderPage,
        name: 'view-order',
        strict: true,
        meta: { child: true, requiresAuth: true },
        props(route: RouteLocationNormalized) {
          return { reference: route.params.reference }
        },
      },
      {
        path: 'files',
        component: ProfileFiles,
        name: 'profile-files',
        strict: true,
        meta: { child: true, requiresAuth: true },
      },
      {
        path: 'details',
        component: ProfileDetails,
        name: 'profile-details',
        strict: true,
        meta: { child: true, requiresAuth: true },
        props(route: RouteLocationNormalized) {
          const initialTab =
            'initialTab' in route.query ? route.query.initialTab : null
          return { initialTab }
        },
      },
      {
        path: 'dropshipping-stores',
        component: DropshippingStoresPage,
        name: 'dropshipping-stores',
        strict: true,
        meta: { child: true, requiresAuth: true },
      },
      {
        path: 'dropshipping-connect-store',
        component: DropshippingConnectStorePage,
        name: 'dropshipping-connect-store',
        strict: true,
        meta: { child: true, requiresAuth: true },
        props: (route: RouteLocationNormalized) => ({
          platformType: route.query.platformType,
        }),
      },
      {
        path: 'dropshipping-view-store/:id',
        component: DropshippingViewStorePage,
        name: 'dropshipping-view-store',
        strict: true,
        meta: { child: true, requiresAuth: true },
        props: idPropFunc,
      },
      {
        path: 'my-products',
        component: MyProductsPage,
        name: 'my-products',
        strict: true,
        meta: { child: true, requiresAuth: true },
        props: (route: RouteLocationNormalized) => ({
          storeId: Number(route.query.storeId),
        }),
      },
      {
        path: 'payment-method-form/:id?',
        component: PaymentMethodFormPage,
        name: 'payment-method-form',
        strict: true,
        meta: { child: true, requiresAuth: true },
        props: (route: RouteLocationNormalized) => ({
          id: route.params.id ? Number(route.params.id) : undefined,
        }),
      },
    ],
  },
  {
    path: '/:lang/ds-product',
    component: DropshippingProductLayout,
    name: 'ds-product',
    meta: { requiresAuth: true },
    strict: true,
    redirect: { name: 'ds-product-details' },
    children: [
      {
        path: 'choose/:categorySlug?',
        component: DropshippingChooseProductPage,
        name: 'ds-product-choose',
        strict: true,
        meta: { requiresAuth: true },
        props: (route: RouteLocationNormalized) => ({
          resetStore: route.query.resetStore === 'true',
        }),
      },
      {
        path: 'view/:slug',
        component: DropshippingViewProductPage,
        name: 'ds-product-view',
        strict: true,
        meta: { requiresAuth: true },
      },
      {
        path: 'details/:id?',
        component: DropshippingProductDetailsPage,
        name: 'ds-product-details',
        strict: true,
        meta: { requiresAuth: true, meta_title: 'Product details' },
        props: (route: RouteLocationNormalized) => ({
          id: route.params.id ? Number(route.params.id) : undefined,
        }),
      },
      {
        path: 'choose-mockups/:id?',
        component: DropshippingChooseMockupsPage,
        name: 'ds-choose-mockups',
        strict: true,
        meta: { requiresAuth: true },
        props: (route: RouteLocationNormalized) => ({
          id: route.params.id ? Number(route.params.id) : undefined,
        }),
      },
    ],
  },
  {
    path: '/:lang/error',
    component: Error,
    name: 'error',
    strict: true,
  },
  {
    path: '/:lang/auth/store-connections',
    component: StoreConnectionsPage,
    name: 'store-connections',
    beforeEnter: (to, _from, next) => {
      const { platformAccessToken, platformStoreId } = to.query

      if (
        platformAccessToken &&
        platformStoreId &&
        platformAccessToken.length > 0 &&
        platformStoreId.length > 0
      ) {
        next()
      } else {
        next({ name: 'error', params: { lang: to.params.lang } })
      }
    },
    props: (route: RouteLocationNormalized) => ({
      platformAccessToken: route.query.platformAccessToken,
      platformStoreId: route.query.platformStoreId,
      connectionVerificationUrl: route.query.connectionVerificationUrl,
    }),
  },
  // LOGIN FLOW
  {
    path: '/:lang/login',
    component: LoginPage,
    name: 'login',
    strict: true,
    meta: { layout: PAGE_LAYOUTS.FORM_FLOW },
    async beforeEnter(to, from, next) {
      const loginStore = useLoginStore()
      await loginStore.isProfileLoaded()
      if (!loginStore.userLoggedIn && FEATURE_FLAGS.SHOW_SIGN_UP_FLOW) {
        next()
      } else {
        next(withLang({ name: 'error' }))
      }
    },
  },
  // CREATE ACCOUNT FLOW
  {
    path: '/:lang/create-account',
    component: CreateAccountPage,
    name: 'create-account',
    strict: true,
    meta: { layout: PAGE_LAYOUTS.FORM_FLOW },
    async beforeEnter(to, from, next) {
      const loginStore = useLoginStore()
      await loginStore.isProfileLoaded()
      if (!loginStore.userLoggedIn && FEATURE_FLAGS.SHOW_SIGN_UP_FLOW) {
        next()
      } else {
        next(withLang({ name: 'error' }))
      }
    },
  },
  {
    path: '/:lang/account-registration',
    component: AccountRegistration,
    name: 'account-registration',
    strict: true,
    meta: { layout: PAGE_LAYOUTS.FORM_FLOW },
    async beforeEnter(to, from, next) {
      const loginStore = useLoginStore()
      await loginStore.isProfileLoaded()
      if (!loginStore.userLoggedIn && FEATURE_FLAGS.SHOW_SIGN_UP_FLOW) {
        next()
      } else {
        next(withLang({ name: 'error' }))
      }
    },
  },
  {
    path: '/:lang/confirm/:id/:token',
    component: FEATURE_FLAGS.SHOW_SIGN_UP_FLOW
      ? PasswordConfirm
      : RegistrationConfirm,
    name: 'registration-confirm',
    strict: true,
    meta: { layout: PAGE_LAYOUTS.FORM_FLOW },
  },

  // PASSWORD RESET FLOW
  {
    path: '/:lang/password-reset-email',
    component: PasswordReset,
    name: 'password-reset-email',
    strict: true,
    meta: { layout: PAGE_LAYOUTS.FORM_FLOW },
    async beforeEnter(to, from, next) {
      const loginStore = useLoginStore()
      await loginStore.isProfileLoaded()
      if (!loginStore.userLoggedIn && FEATURE_FLAGS.SHOW_SIGN_UP_FLOW) {
        next()
      } else {
        next(withLang({ name: 'error' }))
      }
    },
  },
  {
    path: '/:lang/password-reset/:id/:token',
    component: FEATURE_FLAGS.SHOW_SIGN_UP_FLOW
      ? PasswordResetConfirm
      : ResetPassword,
    name: 'password-reset',
    strict: true,
    meta: { layout: PAGE_LAYOUTS.FORM_FLOW },
  },
  // FACEBOOK FLOW/PROVIDE EMAIL
  {
    path: '/:lang/provide-email',
    component: ProvideEmail,
    name: 'provide-email',
    strict: true,
    meta: { layout: PAGE_LAYOUTS.FORM_FLOW },
    beforeEnter: (to, from, next) => {
      if (
        typeof from.name === 'string' &&
        ['login', 'create-account'].includes(from.name) &&
        FEATURE_FLAGS.SHOW_SIGN_UP_FLOW
      ) {
        next()
      } else {
        next(withLang({ name: 'error' }))
      }
    },
  },
  // ONBOARDING FLOW
  {
    path: '/:lang/onboarding',
    component: OnBoardingPage,
    name: 'onboarding',
    strict: true,
    meta: { layout: PAGE_LAYOUTS.FORM_FLOW },
    beforeEnter: (to, from, next) => {
      if (FEATURE_FLAGS.SHOW_ONBOARDING_FLOW) {
        next()
      } else {
        next(withLang({ name: 'error' }))
      }
    },
  },
]

routes.push(...privateTestingRoutes)

export default routes

export const withLang = (to: {
  name: string
  params?: Record<string, any>
  query?: Record<string, any>
}) => ({
  ...to,
  params: { lang: useLangStore().getLang(), ...(to.params ?? {}) },
})
