<template>
  <FormFlow
    v-if="!emailProvided"
    :image-section-alt="__('auth', 'provide-email.image-section.alt')"
    :image-section-src="__('auth', 'provide-email.image-section.url')"
    :show-previous-step-button="false"
  >
    <template #form>
      <h2 class="form-flow-heading">
        {{ __('auth', 'provide-email.main-heading.text') }}
      </h2>
      <form
        v-if="inputs"
        action=""
        class="account-registration-form"
        @submit.prevent="updateProfile"
      >
        <div v-for="item in inputs" :key="item.name">
          <BaseInput
            v-model="item.value"
            :error-message="getFieldError(errors, item.name)"
            :id-name="item.name"
            :label="item.label"
            :type="item.type"
            width="320px"
          />
        </div>
        <div
          :class="{
            error: errors && findErrorField(errors, 'receive_emails'),
          }"
        >
          <div class="account-registration-checkbox">
            <div class="checkbox-left-container">
              <SvgIcon class="mail-icon" name="mail" />
              <span>{{ __('forms', 'agree-to-receive-emails.checkbox') }}</span>
            </div>
            <label class="switch" for="emails">
              <input id="emails" v-model="receiveEmails" type="checkbox" />
              <div class="round slider"></div>
            </label>
          </div>
          <div
            v-if="errors && findErrorField(errors, 'receive_emails')"
            class="errors"
          >
            <div v-for="i in errors" :key="i.field">
              <p v-if="i.field === 'receive_emails'">{{ i.message }}</p>
            </div>
          </div>
        </div>
        <BaseButton
          class="submit-button"
          :class="{ loading }"
          :text="__('forms', 'update')"
          type="submit"
        />
      </form>
    </template>
    <template #alternative-options>
      <div class="alternative-option">
        <span>{{ __('auth', 'provide-email.user-agrees.text') }}</span>
        <a :href="pageLinks ? pageLinks.value.terms_url : ''" target="_blank">{{
          __('auth', 'provide-email.terms-and-conditions.text')
        }}</a>
        <span>{{ __('global', 'and') }}</span>
        <a
          :href="pageLinks ? pageLinks.value.privacy_policy_url : ''"
          target="_blank"
          >{{ __('auth', 'provide-email.privacy-policy.text') }}</a
        >
      </div>
    </template>
  </FormFlow>

  <Success
    v-else
    :main-heading-text="__('auth', 'provide-email-success.main-heading.text')"
    :secondary-heading-text="
      __('auth', 'provide-email-success.update-button.text')
    "
    :submit-button-text="__('global', 'finish')"
  />
</template>

<script setup lang="ts">
import { __ } from '@/Helpers/i18n'
import BaseInput from '@/Components/BaseInput.vue'
import BaseButton from '@/Components/BaseButton.vue'
import FormFlow from '@/Components/FormFlow.vue'
import SvgIcon from '@/Partials/SvgIcon.vue'

import { computed, onMounted, ref } from 'vue'
import { getAcceptSocialInputs } from '@/Helpers/Forms'
import { findErrorField } from '@/Helpers/Global'
import { useLoginStore } from '@/Store/useLoginStore'
import { patchProfile } from '@/Api/Profile'
import { getFieldError, setErrors } from '@/Helpers/Errors'
import Success from '@/Components/Success.vue'
import { useGlobalStore } from '@/Store/useGlobalStore'
import { PageLinkSetting } from '@/Types/Setting'

const loginStore = useLoginStore()
const globalStore = useGlobalStore()
const inputs = ref(getAcceptSocialInputs())
const errors = ref()
const receiveEmails = ref(false)
const loading = ref(false)
const emailProvided = ref(false)

const pageLinks = computed(
  () =>
    globalStore.getSettingByKey('pagelinksetting') as
      | PageLinkSetting
      | undefined,
)

onMounted(() => {
  inputs.value.forEach((item) => {
    if (!loginStore.profile) return
    if (loginStore.profile[item.name as keyof typeof loginStore.profile]) {
      item.value = loginStore.profile[
        item.name as keyof typeof loginStore.profile
      ] as string
    }
  })
})

const updateProfile = async () => {
  loading.value = true
  errors.value = null
  const data: any = {}

  inputs.value.forEach((item: any) => {
    if (item.value) {
      data[item.name] = item.value
    }
  })

  data.terms_accepted = true
  data.receive_emails = receiveEmails.value
  await patchProfile(data).catch((error: any) => {
    errors.value = setErrors(error)
  })

  loading.value = false

  if (errors.value) return
  emailProvided.value = true

  inputs.value.forEach((item: any) => {
    item.value = null
  })
}
</script>

<style lang="scss" scoped>
.form-flow-secondary-heading {
  max-width: 445px;
  text-align: center;
}

.account-registration-form {
  @include centered-flex-column;
  margin-top: 32px;

  @media (min-width: 768px) {
    margin-top: 40px;
  }

  .submit-button {
    margin-top: 48px;
  }
}

.account-registration-checkbox {
  @include centered-flex;
  justify-content: space-between;
  background: $gray-6;
  width: 320px;
  height: 56px;
  padding: 16px;
  border-radius: 16px;
  gap: 16px;

  .checkbox-left-container {
    display: flex;
    align-items: center;
    gap: 12px;

    .mail-icon {
      height: 32px;
      width: 32px;
    }

    span {
      font-size: 12px;
      max-width: 200px;
    }
  }
}

.switch {
  display: inline-block;
  height: 24px;
  position: relative;
  width: 42px;
}

.switch input {
  display: none;
}

.slider {
  background-color: $gray-8;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: '';
  height: 16px;
  left: 4px;
  position: absolute;
  transition: 0.3s;
  width: 16px;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $checkbox-green;
}

input:checked + .slider:before {
  transform: translateX(16px);
}

.slider:after {
  content: '';
  position: absolute;
  top: 7.4px;
  left: 10px;
  width: 2.5px;
  height: 5.3px;
  border: solid $white;
  border-width: 0 1.8px 1.8px 0;
  transform: translate(0, 0) rotate(45deg); /* Initially hidden */
  transition: transform 0.3s ease;
}

input:checked + .slider:after {
  transform: translate(16px, 0) rotate(45deg); /* Show the checkmark */
  border: solid $checkbox-green;
  border-width: 0 1.8px 1.8px 0;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
