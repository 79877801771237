/* maybe this should be defined in CMS, but works for now. */
export const SAMPLE_SETS_SLUGS = [
  'sample-sets',
  'probensets',
  'kits-dechantillons',
] as const
export const COLLECTION_BOXES_SLUG = 'collection-box' as const
export const PRO_PRODUCTS_SLUG = 'pro-products' as const
export const PRO_PRODUCTS_ROUTE_NAME = 'Pro products' as const
export const MAKEUP_CATEGORY_SLUGS = [
  'makeup',
  'das-make-up',
  'se-maquiller',
] as const

export const DEFAULT_LANG = 'en' as const
export const DEFAULT_ROUTE_NAME = 'home' as const

export const DAYS_TO_SHOW_DESIGN_TIPS_INDICATOR = 30 as const

export const SN_SHOPIFY_APP_URL =
  'https://apps.shopify.com/selfnamed-cosmetics-on-demand' as const

export const MIN_DESKTOP_PX = 992
export const MAX_MOBILE_PX = MIN_DESKTOP_PX - 1

export const STATIC_CONTENT_PATH_PREFIX = '/static/'

export const US_FL_OZ_TO_ML = 29.5735295625

export const HELP_SITE_URL = 'https://help.selfnamed.com' as const

export const SERVICE_CALCULATOR_SECTION_HEADER_ID =
  'service-calculator-section-header'
export const DISCOUNT_KEYS = {
  ECOMMERCE: 'ECOMMERCE',
  SELFNAMED: 'SELFNAMED',
  FIRST_ORDER: 'FIRST_ORDER',
}

export const UNITED_STATES_COUNTRY_CODE = 'US' as const

export const PAGE_LAYOUTS = {
  FORM_FLOW: 'form-flow',
} as const
