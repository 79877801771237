import { useGlobalStore } from '@/Store/useGlobalStore'

/** add new groups as needed */
const groups = [
  'about-us',
  'alt-tags',
  'cart',
  'checkout',
  'constructor',
  'dashboard',
  'design-offer-popup',
  'design',
  'dropshipping-products',
  'dropshipping-stores',
  'dropshipping',
  'dropshipping-landing',
  'error',
  'footer',
  'forms',
  'global',
  'header',
  'home',
  'html',
  'pro-signup-popup',
  'products',
  'profile',
  'registration-confirm',
  'sample-sets-tool',
  'services',
  'sign-up',
  'how-it-works-landing',
  'product-category-landings',
  'order',
  'onboarding',
  'auth',
] as const

export const __ = (
  group: (typeof groups)[number],
  message: string,
  emptyIfNotEntered = false,
) => {
  const globalStore = useGlobalStore()

  const translations = globalStore.translations

  if (!translations) {
    console.warn(
      '[SN warn] Translation error: translations not set',
      JSON.parse(JSON.stringify(translations)),
      group,
      message,
    )
  }

  const translation = translations?.[group]?.[message]
  if (emptyIfNotEntered && (!translation || translation === message)) return ''
  return translation ?? message
}
