import {StoreResource} from './Store'
import {DropShippingData} from './DropShippingData'
import OrderItemVM from '@/ViewModels/OrderItemVM'
import {OrderItemResource} from './OrderItem'
import {CustomerResource} from './Customer'
import {MaybeExpandedResourceAttribute} from './Resource'
import {PaymentSessionResource} from './PaymentSession'
import {OrderAddressResource} from '@/Types/OrderAddress'

export type Status = {
  id: number
  codes: string[]
  value: string
}

//source: OrderTypeEnum.php
export enum OrderType {
  regular = 'REGULAR',
  dropshipped = 'DROPSHIPPED',
}

export enum OrderStatus {
  IN_BASKET = 'IN_BASKET',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  CANCELLED = 'CANCELLED',
  AWAITING_CANCELLATION = 'AWAITING_CANCELLATION',
  CANCELLATION_FAILED = 'CANCELLATION_FAILED',
  ACCEPTED = 'ACCEPTED',
  FILE_ISSUES = 'FILE_ISSUES',
  FILE_SENT = 'FILE_SENT',
  FILE_ACCEPTED = 'FILE_ACCEPTED',
  PRINTING = 'PRINTING',
  ASSEMBLING = 'ASSEMBLING',
  DELIVERED = 'DELIVERED',
  SHIPPED = 'SHIPPED',
  DESIGN_CHECK = 'DESIGN_CHECK',
}

export type OrderForm = {
  is_unread: 1 | 0
  status: OrderStatus
}

export type OrderResourceMainFields = {
  id: number
  nr: string
  reference: string | null
  customer_id: number
  delivery_order_address_id: number | null
  billing_order_address_id: number | null
  status: OrderStatus
  status_decoded: string
  subtotal: number
  shipping_price: number | null
  total: number | null
  tax_rate: string
  purchased_at: string
  invoice_file: string
  transaction_id: string | null
  total_tax: number | null
  tracking_ids: string[]
  tracking_urls: string[] | null
  shipping_type_title: string
  discount: number | null
  is_unread: boolean
  store_id: number
  buyer_invoice_file: string | null
  type: OrderType
  currency_code: string | null
  user_discount: number | null
  first_order_discount?: number | null
}

// expandable fields; not expanded (undefined) by default
export type OrderResourceExpandableFields = {
  customer: CustomerResource | null
  order_items: OrderItemResource[]
  store: StoreResource | null
  drop_shipping_data: DropShippingData | null
  payment_session: PaymentSessionResource | null
  delivery_order_address: OrderAddressResource | null
  billing_order_address: OrderAddressResource | null
}

export type OrderResourceDeepExpandableProperties = {
  order_items: 'product'
}

export type OrderResourceExpandableProperties =
  | keyof OrderResourceExpandableFields
  | OrderResourceDeepExpandableProperties

export type OrderResource<
  T extends OrderResourceExpandableProperties | never = never,
> = OrderResourceMainFields & {
  [K in keyof OrderResourceExpandableFields]: K extends keyof OrderResourceDeepExpandableProperties
    ? T extends OrderResourceDeepExpandableProperties
      ? OrderItemResource<T['order_items']>[]
      : OrderItemResource[]
    : MaybeExpandedResourceAttribute<OrderResourceExpandableFields, K, T>
}

export type OrderModified = Omit<
  OrderResource<'customer' | { order_items: 'product' }>,
  'cart_items'
> & { cart_items: OrderItemVM[] }
