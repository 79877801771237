<template>
  <FormFlow
    :image-section-alt="__('auth', 'success.image-section.alt')"
    :image-section-src="__('auth', 'success.image-section.url')"
    :show-previous-step-button="false"
  >
    <template #form>
      <h1 class="custom-heading-style">
        {{ mainHeadingText ?? __('auth', 'success.main-heading.text') }}
      </h1>
      <h5 class="form-flow-secondary-heading">
        {{
          secondaryHeadingText ?? __('auth', 'success.secondary-heading.text')
        }}
      </h5>
      <router-link
        class="btn btn-primary password-sent-btn"
        :to="`/${langStore.getLang()}${submitButtonUrl}`"
      >
        {{ submitButtonText ?? __('auth', 'success.submit-button.text') }}
      </router-link>
    </template>
  </FormFlow>
</template>

<script setup lang="ts">
import FormFlow from '@/Components/FormFlow.vue'
import { __ } from '@/Helpers/i18n'
import useLangStore from '@/Store/useLangStore'

withDefaults(
  defineProps<{
    mainHeadingText?: string | null
    secondaryHeadingText?: string | null
    submitButtonText?: string | null
    submitButtonUrl?: string
  }>(),
  {
    mainHeadingText: null,
    secondaryHeadingText: null,
    submitButtonText: null,
    submitButtonUrl: '',
  },
)

const langStore = useLangStore()
</script>

<style lang="scss" scoped>
.custom-heading-style {
  font-size: 44px;
  text-align: center;
  max-width: 650px;
}

.form-flow-secondary-heading {
  max-width: 445px;
  margin-top: 32px;
}

.password-sent-btn {
  margin-top: 204px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
}
</style>
