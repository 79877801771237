<template>
  <div class="sign-in-form-wrapper">
    <div v-if="status === type.REGISTRATION || status === type.LOGIN">
      <div class="sign-up-top">
        <h4>
          {{
            __(
              'global',
              status === type.REGISTRATION ? 'Create an account' : 'Sign in',
            )
          }}
        </h4>
        <div class="socials-sign-up">
          <button class="fb-login-button" @click.prevent="loginViaFacebook">
            <SvgIcon name="facebook-login" />
          </button>
          <div class="g-login-button">
            <GoogleLogin :callback="loginViaGoogle" />
          </div>
        </div>
        <div
          v-if="loginStore.errors && (tokenIdError || accessTokenError)"
          class="errors"
        >
          <p v-if="accessTokenError">{{ accessTokenError.message }}</p>
          <p v-if="tokenIdError">{{ tokenIdError.message }}</p>
        </div>
      </div>
      <div class="separator">
        <span>{{ __('global', 'or') }}</span>
      </div>
      <div class="sign-up-bottom">
        <Registration
          v-if="status === type.REGISTRATION"
          @switch-to-login="switchToLogin"
          @switch-to-success="switchToSuccess"
        />
        <Login
          v-if="status === type.LOGIN"
          @redirect-after-login="redirectAfterLogin"
          @switch-to-forgot-password="switchToForgotPassword"
          @switch-to-registration="switchToRegistration"
        />
      </div>
    </div>
    <div v-if="status === type.SUCCESS_REGISTRATION" class="sign-up-bottom">
      <SuccessRegistration
        :description="successDescription"
        :title="successTitle"
      />
    </div>
    <div v-if="status === type.FACEBOOK_EMAIL" class="sign-up-bottom">
      <UpdateProfile @switch-to-success="switchToSuccess" />
    </div>
    <div v-if="status === type.FACEBOOK_CONFIRMATION" class="sign-up-bottom">
      <h4>Please confirm your email address</h4>
    </div>
    <div v-if="status === type.FORGOT_PASSWORD" class="sign-up-bottom">
      <ForgotPassword @switch-to-success="switchToSuccess" />
    </div>
  </div>
</template>

<script setup lang="ts">
import UpdateProfile from '../Partials/UpdateProfile.vue'
import ForgotPassword from '../Partials/ForgotPassword.vue'
import SuccessRegistration from '../Partials/SuccessRegistration.vue'
import { RouteLocationRaw, useRouter } from 'vue-router'
import { useCartStore } from '../Store/useCartStore'
import { useLoginStore } from '../Store/useLoginStore'
import { computed, ref, watch } from 'vue'
import { findErrorField } from '@/Helpers/Global'
import {
  analyticsCompleteRegistration,
  analyticsLogin,
  analyticsSignUp,
} from '@/Helpers/Analytics'
import { withLang } from '../routes'
import { __ } from '@/Helpers/i18n'
import Registration from '../Partials/Registration.vue'
import Login from '../Partials/Login.vue'
import SvgIcon from '../Partials/SvgIcon.vue'

const props = withDefaults(
  defineProps<{
    login: boolean
    redirectUrl?: RouteLocationRaw | null
    noRedirect?: boolean
  }>(),
  {
    redirectUrl: null,
    noRedirect: false,
  },
)

const emit = defineEmits<{
  successfulLogin: []
}>()

const cartStore = useCartStore()
const loginStore = useLoginStore()
const router = useRouter()

const type = {
  REGISTRATION: 1,
  LOGIN: 2,
  SUCCESS_REGISTRATION: 3,
  FACEBOOK_EMAIL: 4,
  FACEBOOK_CONFIRMATION: 5,
  FORGOT_PASSWORD: 6,
}

const status = ref(type.REGISTRATION)

const successTitle = ref()
const successDescription = ref()

const switchToSuccess = (data: { title: string; description: string }) => {
  successTitle.value = data.title
  successDescription.value = data.description
  status.value = type.SUCCESS_REGISTRATION
}

const switchToLogin = () => {
  status.value = type.LOGIN
}

const switchToRegistration = () => {
  status.value = type.REGISTRATION
}

const switchToForgotPassword = () => {
  status.value = type.FORGOT_PASSWORD
}

const accessTokenError = computed(() =>
  findErrorField(loginStore.errors, 'access_token'),
)
const tokenIdError = computed(() =>
  findErrorField(loginStore.errors, 'token_id'),
)

const loginViaGoogle = async (response: unknown) => {
  if (
    !response ||
    typeof response !== 'object' ||
    !('credential' in response) ||
    typeof response.credential !== 'string'
  ) {
    console.error('response.credential must be string!; response: ', response)
    return
  }
  try {
    await loginStore.postGoogleLogin(response.credential)
    if (!loginStore.errors && loginStore.profile) {
      if (loginStore.profile.new_sign_up) {
        analyticsSignUp(loginStore.profile)
        analyticsCompleteRegistration(loginStore.profile.id)
      } else {
        analyticsLogin('Google')
      }
      await cartStore.initializeCartIfAuthenticated()
      await redirectAfterLogin()
    }
  } catch (error) {
    console.log(error)
    return null
  }
}

const loginViaFacebook = async () => {
  await loadFacebookSDK()

  initFacebook()
  window.FB.login(
    (response: any) => {
      if (response.authResponse) {
        loginStore
          .postFacebookLogin(response.authResponse.accessToken)
          .then(() => {
            if (!loginStore.errors && loginStore.profile) {
              if (loginStore.profile.new_sign_up) {
                analyticsSignUp(loginStore.profile)
                analyticsCompleteRegistration(loginStore.profile.id)
              } else {
                analyticsLogin('Facebook')
              }

              cartStore.initializeCartIfAuthenticated()
              if (
                loginStore.profile &&
                loginStore.profile.email &&
                loginStore.profile.is_confirmed
              ) {
                redirectAfterLogin()
              } else if (loginStore.profile && loginStore.profile.email) {
                status.value = type.FACEBOOK_CONFIRMATION
              } else {
                status.value = type.FACEBOOK_EMAIL
              }
            }
          })
      }
    },
    {
      scope: 'email,public_profile',
    },
  )
}

const loadFacebookSDK = () =>
  new Promise((resolve) => {
    const js = document.getElementById('facebook-jssdk'),
      fjs = document.getElementsByTagName('script')[0]
    if (js) {
      resolve(js)
    }
    const newJs = document.createElement('script')
    newJs.id = 'facebook-jssdk'
    newJs.src = 'https://connect.facebook.net/en_US/sdk.js'
    newJs.addEventListener('load', () => {
      resolve(newJs)
    })
    fjs.parentNode?.insertBefore(newJs, fjs)
  })

const initFacebook = () => {
  window.fbAsyncInit = () => {
    window.FB.init({
      appId: import.meta.env.VITE_FACEBOOK_APP_ID,
      version: import.meta.env.VITE_FACEBOOK_API_VERSION,
    })
  }
}

const redirectAfterLogin = async () => {
  emit('successfulLogin')
  if (props.noRedirect) return

  await router.push(props.redirectUrl ?? withLang({ name: 'profile' }))
}

watch(
  () => props.login,
  (n) => {
    n ? switchToLogin() : switchToRegistration()
  },
  { immediate: true },
)
</script>

<style lang="scss" scoped>
.sign-in-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
h4 {
  text-align: center;
  text-transform: none;
  font-weight: 500;
  letter-spacing: -0.096rem;
  line-height: 140%;
  @media (min-width: 768px) {
    font-size: 2.4rem;
  }
}

.sign-up-top {
  padding: 0 2rem;
  @media (min-width: 768px) {
    padding: 0 6rem;
  }
}

.sign-up-bottom {
  padding: 0 2rem;
  @media (min-width: 768px) {
    padding: 0 6rem;
  }
}

.socials-sign-up {
  display: flex;
  column-gap: 0.4rem;
  justify-content: center;
  align-items: stretch;
  margin: 6.6rem 0 1.2rem 0;
  max-height: 4rem;
  @media (min-width: 768px) {
    margin: 3.2rem 0 1.2rem 0;
  }

  .g-login-button {
    overflow: hidden;
    :deep(svg) {
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      height: 18px;
      width: 18px;
    }
  }

  .fb-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $google-signin-icon-borer-color;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    svg {
      width: 20px;
      height: 20px;
      color: $vibrant-blue;
    }
  }
}

.separator {
  margin: 1.2rem 0 2.8rem 0;
  text-transform: uppercase;
  font-size: 1rem;
  color: $gray-9;
  @media (min-width: 768px) {
    margin: 0 0 1.8rem 0;
  }
}

:deep(.forget-wrapper) {
  display: flex;
  text-align: right;
  justify-content: center;
  margin: 0 0 0.8rem 0;
}

:deep(.forgot) {
  color: $dark-purple;
  font-size: 1.2rem;
  display: inline-block;
  line-height: 160%;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

:deep(.register-wrapper) {
  font-size: 1.2rem;
  color: $gray-7;
  text-align: center;

  button {
    color: $dark-purple;
    border: none;
    background: none;
    padding: 0;
  }
}

:deep(.button-wrap) {
  margin: 9.6rem 0 1rem 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  @media (min-width: 768px) {
    margin: 3.8rem 0 1rem 0;
  }

  .btn {
    width: auto;
    margin: 0 0.3rem;
  }
}

button {
  width: 100%;
  max-width: 278px;
}

.input-checkbox {
  margin-bottom: 1.4rem;

  label {
    font-size: 1.2rem;
  }
}

.errors {
  color: $red;
  font-size: 1.2rem;
}
</style>
